import React, { useState } from "react";
import './contact.css'

const ContactForm = () => {
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission

    const formData = new FormData(e.target);

    try {
      const response = await fetch('https://formspree.io/f/xjvnyknp', {
        method: 'POST',
        body: formData,
        headers: {
          Accept: 'application/json',
        },
      });

      if (response.ok) {
        // Successfully submitted
        setSubmitted(true);
        // Optionally, reset the form or handle the UI feedback
      } else {
        // Handle server errors or invalid responses
        throw new Error('Submission failed');
      }
    } catch (error) {
      // Handle errors here, such as updating the UI to show an error message
      console.error('Error:', error);
    }
  };

  // Always render the form unless explicitly choosing to show a success message
  return (
    <>
      {submitted ? (
        <div>Thank you! I have received your message and will be in touch soon.</div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div>
            <label>Email Address:</label>
            <input type="email" name="email" required />
          </div>
          <div>
            <label>Message:</label>
            <textarea name="message" required />
          </div>
          <div>
            <button type="submit">Send a message</button>
          </div>
        </form>
      )}
    </>
  );
};

export default ContactForm;
