import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";

import Projects from "./components/Projects";
import About from "./components/About";
import Contact from "./components/Contact";
import Home from ".//components/Home";



function App() {
  return (
   

     <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navbar />}>
          <Route index element={<Home />} />
          <Route path="Projects" element={<Projects />} />
          <Route path="Contact" element={<Contact />} />
          <Route path="About" element={<About />} />
          
        </Route>
      </Routes>
    </BrowserRouter>
   
  );
}

export default App;