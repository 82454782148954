import React from 'react';
import { NavLink } from 'react-router-dom'
import { Outlet, Link } from "react-router-dom";
import './navbar.css'

	

const Footbar = () => {
  return (
      <>
    <nav class="footbar">
      
      <div className="bottomnav">
              
       
             <a href="https://www.facebook.com/david.howell.1447" alt="none" target="_blank">Facebook</a>  <a href="https://www.linkedin.com/in/davidhowell0101/" alt="none" target="_blank">Linkedin</a>    <a href="https://github.com/drhowell440" alt="none" target="_blank">Github</a> 

         
              </div>
    </nav>
    
    </>
  )
}

export default Footbar

