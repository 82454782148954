import React from 'react';
import './pages.css'

const Projects = () => {
   
  const projectImages = {
    truckersFuelTax: '/images/4.png',
    stage2: '/images/7.png',
    worldViewReports: '/images/1.png',
    musicVisualizer: '/images/2.png',
    websiteGenerator: '/images/5.png',
    designs3d: '/images/6.png',
  };
    
  return (
   <div className="content">
    <div className="projects">
   <div class="projectsContainer">

<div class="card">
 <img src={projectImages.truckersFuelTax} alt="Truckers Fuel Tax and More" />
<div class="contentBx">
<h2>Truckers Fuel Tax and More</h2>
<p>
This site uses a mix of javascript and php. It manages customer accounts using a mariaDB Mysql database. 
The payments are handled with authorize.net and jotform. </p>
<a href="https://truckersfueltaxandmore.com/" ><span>Visit site</span></a>
</div>
</div>


<div class="card">
 <img src={projectImages.stage2} alt="Stage 2" />
<div class="contentBx">
<h2>Stage 2</h2>
<p>Mass Multiplayer Onlne Platform Shooter. Register an account and battle your friends.</p>
<a href="http://stagetwo.space:3000/"><span>Visit Site</span></a>
</div>
</div>

<div class="card">
 <img src={projectImages.worldViewReports} alt="World View Reports" />
<div class="contentBx">
<h2>World View Reports</h2>
<p>This is a website designed for an online journalis/media company.</p>
<a href="http://worldviewreports.com"><span>Visit Site</span></a>
</div>
</div>

<div class="card">
 <img src={projectImages.musicVisualizer} alt="Music Visualizer" />
<div class="contentBx">
<h2>Music Visualizer</h2>
<p>This is a music visualizer that you can upload an mp3 file to, and
it will generate a video sequence in rythm and playback immediately with an option to download.</p>
<a href="http://davidslaboratory.esy.es/visualizer.php"><span>Visit Site</span></a>
</div>
</div>

<div class="card">
 <img src={projectImages.websiteGenerator} alt="Website Generator" />
<div class="contentBx">
<h2>Website Generator</h2>
<p>This website allows you to add some basic information into a form and then it generates the html code for a simple website based off of that information. </p>
<a href="http://davidslaboratory.esy.es/webgen.php"><span>Visit Site</span></a>
</div>
</div>



<div class="card">
 <img src={projectImages.designs3d} alt="3d Designs" />
<div class="contentBx">
<h2>3d Designs</h2>
<p>A collection of 3d designs I have created for animation and printing</p>
<a href="https://www.thingiverse.com/drhowell440/designs"><span>Visit Site</span></a>
</div>
</div>

</div>
   </div>
   </div>
  )
}

export default Projects