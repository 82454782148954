import React from 'react';
import './pages.css'

const About = () => {
  return (
   <div className="content">
   <div className="aboutContainer">
   
    <p>
 I'm currently 31 years old and have had a lifelong passion for technology and development. My journey in programming started when I was just a child, laying a strong foundation in various programming languages and technologies over the years.

Educationally, my focus has always been on expanding my technical expertise through both formal and self-directed learning. My real-world experience has been my greatest teacher.

 </p>
   
  </div>
   </div>
  )
}

export default About