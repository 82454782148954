import React from 'react';
import './pages.css'
import ContactForm from "./ContactForm";



const Contact = () => {
  return (
 <div className="content">
    <div className="pageContainer">
          <night-sky
    id="nightSky"
    layers="4"
    density="30"
    velocity="30"
    starcolor="#FFF"
>
</night-sky>
<div className="contact">
   <ContactForm />
   </div>
   </div>
   
   </div>
  )
}

export default Contact