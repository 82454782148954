import React from 'react';
import './pages.css'

const Home = () => {
  return (
   <div className="content">
   <div className="homeContainer">
   <div className="firstName">DAVID</div>
   
   <div className="lastName">HOWELL</div>
   <div className="subHead">Full Stack Developer</div>
   </div>
   </div>
 
  

  )
}

export default Home