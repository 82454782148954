import React from 'react';
import { NavLink } from 'react-router-dom'
import { Outlet, Link } from "react-router-dom";
import './navbar.css'
import Projects from "./Projects";
import About from "./About";
import Contact from "./Contact";
import Home from "./Home";
import Footer from "./Footer";

const Navbar = () => {
  return (
      <>
    <nav class="navbar">
      
     
      
      <div className="topnav">
              
       
              <p className="logo">David R Howell</p>
              
       
              <NavLink to="/Contact" className="link">Contact</NavLink>
              
              <NavLink to="/Projects" className="link">Projects</NavLink>

              <NavLink to="/About" className="link">About</NavLink>
              
              <NavLink to="/" className="link">Home</NavLink>
              
            
          
              
              </div>
    </nav>
    
    <Outlet />
    <Footer />
    </>
  )
}

export default Navbar